import * as Sentry from '@sentry/browser'
import type { Integration } from '@sentry/types'

export {}

declare const SENTRY_DSN: string | undefined
declare const posthog: {
  SentryIntegration: { new (ph: unknown, organization: string, projectId: number): Integration }
}

if (typeof SENTRY_DSN === 'string') {
  const cleanupSelectors = (message: string) =>
    message
      .split(' > ')
      .map(part => {
        const [tag, ...classNames] = part.split('.')
        const componentLabels = classNames
          .map(cn =>
            cn
              .split('-')
              .filter(p => /^[A-Z]/.test(p))
              .join('-'),
          )
          .filter(cn => cn.length > 0)
        return componentLabels.length === 0 ? tag : `${tag}.${componentLabels.join('.')}`
      })
      .join(' > ')

  const integrations: Integration[] = []
  if (typeof posthog === 'object' && posthog?.SentryIntegration) {
    const projectId = parseInt(SENTRY_DSN.split('/').pop()!, 10)
    integrations.push(new posthog.SentryIntegration(posthog, 'dropscan-gmbh', projectId))
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: 'ds-customer-app-js',
    blacklistUrls: [/^file:\/\//],
    whitelistUrls: [/dropscan.de/],
    beforeBreadcrumb(bc, hint) {
      if (bc.category === 'ui.click' && hint && hint.event && hint.event.target) {
        bc.message = bc.message ? cleanupSelectors(bc.message) : ''
        const el = hint.event.target as HTMLElement
        if (el.tagName === 'A' || el.tagName === 'BUTTON' || el.tagName === 'LABEL') {
          bc.message += ` "${el.textContent}"`
        }
      }
      return bc
    },
    beforeSend(event, hint) {
      if (hint && hint.originalException) {
        const message =
          typeof hint.originalException === 'object'
            ? hint.originalException.message
            : hint.originalException
        if (/PSPDFKit/.test(message)) {
          event.fingerprint = ['PSPDFKit']
        }
        if (/network/i.test(message)) {
          return null
        }
        if (/Loading chunk \d+ failed/.test(message)) {
          return null
        }
        if (/instantSearchSDKJSBridgeClearHighlight/.test(message)) {
          return null
        }
      }
      return event
    },
    integrations,
  })
}
